import { getEnv } from 'systems/helper'

export enum ROUTE {
  HOME = '/',
  LOGIN = '/login',
  NOT_FOUND = '*',
  PROJECTS = '/projects',
  PROJECTS_CREATE = '/projects/create',
  PROJECT_DETAIL = '/projects/:id',
  ESTIMATE_CREATE = '/projects/:projectId/estimate/create',
  ESTIMATE_DETAIL = '/projects/:projectId/estimate/:id',
  ESTIMATE_DETAIL_APPROVE = '/projects/approve/:projectId/estimate/:id',
  ORDER_CREATE = '/projects/:projectId/order/create',
  ORDER_DETAIL = '/projects/:projectId/order/:id',
  BUDGET_CREATE = '/projects/:projectId/budget/create',
  BUDGET_DETAIL = '/projects/:projectId/budget/:id',
  ACTUAL_COST_CREATE = '/projects/:projectId/actual-cost/create',
  ACTUAL_COST_DETAIL = '/projects/:projectId/actual-cost/:id',
  FINAL_CREATE = '/projects/:projectId/final/create',
  FINAL_DETAIL = '/projects/:projectId/final/:id',
  BILL_CREATE = '/projects/:projectId/bill/create',
  BILL_DETAIL = '/projects/:projectId/bill/:id',
  TECHNICAL_DRAWING = '/technical-drawings',
  PURCHASE_ORDER = '/purchase-order',
  ESTIMATE = '/estimate',
  ADMIN_MENU = '/admin',
  EMPLOYEE = '/admin/employees',
  ACCOUNT = '/admin/account',
  VENDOR = '/admin/contractors',
  VENDOR_USER = '/admin/vendor-user',
  PRODUCT = '/admin/product',
  TEMPLATE = '/admin/template',
  SETTING = '/setting',
  NEWS = '/setting/news',
  RECOVER_PASSWORD = '/recover-password',
  RESET_PASSWORD = '/reset-password',
  LOGOUT = '/logout'
}

export enum STORAGE_KEY {
  ACCESS_TOKEN = 'access_token'
}

export enum DATE_FORMAT {
  DEFAULT = 'YYYY/MM/DD'
}

export const API_BASE_URL = getEnv('REACT_APP_SERVER_API')

export const listUnit = [
  { value: 'm', label: 'm' },
  { value: '㎡', label: '㎡' },
  { value: '巻', label: '巻' },
  { value: '個', label: '個' },
  { value: '袋', label: '袋' },
  { value: '本', label: '本' },
  { value: '枚', label: '枚' },
  { value: 'セット', label: 'セット' }
]

export const steps = [
  {
    title: '見積中',
    value: 1
  },
  { title: '見積済', value: 2 },
  { title: '再見積中', value: 3 },
  { title: '契約', value: 4 },
  { title: '予算（原価管理表）提出', progress: '工事実行', value: 5 },
  { title: '実績（原価管理表）', value: 6 },
  { title: '完工済', value: 7 },
  { title: '請求書', value: 8 },
  { title: '入金済み', value: 9 }
]

export const stepsOption = [
  {
    label: '見積中',
    value: '1'
  },
  { label: '見積済', value: '2' },
  { label: '再見積中', value: '3' },
  { label: '契約', value: '4' },
  { label: '予算（原価管理表）提出', value: '5' },
  { label: '実績（原価管理表）', value: '6' },
  { label: '完工済', value: '7' },
  { label: '請求書', value: '8' },
  { label: '入金済み', value: '9' }
]

export const activeStep = ['0', '1', '2', '3', '4', '5', '6', '7', '8']

export const defaultPerPage = 10000

export const estimateStatus = {
  approved: 'approved',
  reject: 'rejected'
}

export const maxPrice = 10000000000000

export const tax = 0.1

export const billStatus = [
  {
    label: '未入金',
    value: 1
  },
  {
    label: '入金済み',
    value: 2
  },
  {
    label: '不足',
    value: 3
  },
  {
    label: '過剰',
    value: 4
  }
]

export const paymentMethod = [
  {
    label: '現金入金',
    value: 1
  },
  {
    label: '振込入金',
    value: 2
  }
]

export const contractorKind = {
  orderer: 1,
  primaryContractor: 2,
  supplier: 3
}

export const optionsContractorKind = [
  { label: '発注先', value: 1 },
  { label: '元請', value: 2 },
  { label: '仕入れ先', value: 3 }
]

export const listOptionsContractorKind = [
  { label: '全て', value: null },
  { label: '発注先', value: '1' },
  { label: '元請', value: '2' },
  { label: '仕入れ先', value: '3' }
]

export const mappingContractor: Record<number, string> = {
  1: '発注先',
  2: '元請',
  3: '仕入れ先'
}

export const PDFContractorKind = {
  type1: { value: 1, text: '井桁藤' },
  type2: { value: 2, text: '彩加' }
}

export const projectTab = {
  drawing: {
    label: '図面登録',
    key: 'drawing'
  },
  estimate: {
    label: '見積作成',
    key: 'estimate'
  },
  approveEstimate: {
    label: '見積承認・差戻',
    key: 'approve-estimate'
  },
  contract: {
    label: '契約書登録',
    key: 'contract'
  },
  order: {
    label: '発注書登録',
    key: 'order'
  },
  budget: {
    label: '予算登録',
    key: 'budget'
  },
  actualCost: {
    label: '実績登録',
    key: 'actual-cost'
  },
  buildFinal: {
    label: '完工登録',
    key: 'build-final'
  },
  bill: {
    label: '請求書作成',
    key: 'bill'
  },
  payment: {
    label: '入金登録',
    key: 'payment'
  }
}

export const finishProject = 9

export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,20}$/

export const templateOptions = [
  { value: '防水紙開発', label: '防水紙開発' },
  { value: 'ウレタン ', label: 'ウレタン ' },
  { value: 'SD開発、納品', label: 'SD開発、納品' },
  { value: 'シール完了', label: 'シール完了' },
  { value: '樋工事完了', label: '樋工事完了' },
  { value: '足場払い', label: '足場払い' }
]

export const productType = [
  { value: 0, label: '材' },
  { value: 1, label: '工' }
]
